import * as React from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

const CustomCheckBox = (props) => {
    const { checked, onChange, label, sx, ...rest } = props;

    return (
        <Box>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={onChange}
                        sx={{
                            color: "#B190FF",
                            '&.Mui-checked': {
                                color: "#B190FF",
                            },
                            ...sx // Merging additional styling from props
                        }}
                        {...rest} // Passing additional props like name, disabled, etc.
                    />
                }
                label={label}
                sx={{
                    color: "black",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: { xs: "16px", sm: "18px" },
                    mb: '8px',
                    ...sx // Merging additional styling from props
                }}
            />
        </Box>
    );
};

export default CustomCheckBox;
