import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton, Tooltip } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AxiosInstance from "../../../Config/AxiosInstance";
import CustomSubmitButton from "../../../Components/CustomSubmitButton/CustomSubmitButton";
import Swal from "sweetalert2";
import APILIST from '../../../Config/ApiList';
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import EDIT_ICON from '../../../Assets/Images/Common/edit.svg';
import DELETE_ICON from '../../../Assets/Images/Common/delete.svg';
const Faq = () => {
    const [busy, setBusy] = useState(false);
    const [faqList, setFaqList] = useState([]);
    const [typeCheck, setTypeCheck] = useState("add");
    const [editFAQid, setEditFAQId] = useState(null);
    useEffect(() => {
        loadFaqList();
    }, []);

    const loadFaqList = async () => {
        setBusy(true);
        try {
            //   const response = await AxiosInstance.get("/admin/faq/get");
            const response = await AxiosInstance.get(APILIST.GET_FAQ);
            if (response && response?.data?.s === 1) {
                setFaqList(response?.data?.r);
                setBusy(false);
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                    title: "error",
                    text: response?.data?.m,
                });
                setBusy(false);
            }
        } catch (error) {
            console.log("error", error);
            setBusy(false);
        }
    };

    const handleDeleteHelpSupport = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "you want to Delete Faq?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#46adf2",
            cancelButtonColor: "#5b5d5d",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await AxiosInstance.post(APILIST.UPDATE_FAQ, {
                        id: id,
                        status: 0
                    });
                    if (response?.data?.s) {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 1500,
                            title: "Deleted!",
                            text: "Faq Deleted Successfully",
                        });
                        loadFaqList();
                    } else {
                        Swal.fire({
                            title: "Update!",
                            text: response?.data?.m,
                            icon: "error",
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            }
        });
    };
    const handleEditHelpSupport = (id) => {
        setTypeCheck("edit");
        setEditFAQId(id);
        const editHelpSupport = faqList?.find((elem) => id === elem?.id);
        formik.setValues({
            question: editHelpSupport?.question,
            answer: editHelpSupport?.answer,
        });
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            question: "",
            answer: "",
        },
        validationSchema: Yup.object({
            question: Yup.string().required("Question Is Required"),
            answer: Yup.string().required("Answer Is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            if (busy) return;
            setBusy(true);
            try {
                let response;
                if (typeCheck === "edit") {
                    response = await AxiosInstance.post(APILIST.UPDATE_FAQ, {
                        question: values.question,
                        answer: values.answer,
                        id: editFAQid,
                    });
                } else {
                    response = await AxiosInstance.post(APILIST.ADD_FAQ, {
                        question: values.question,
                        answer: values.answer,
                    });
                }
                const isSuccess = response?.data?.s === 1;
                Swal.fire({
                    position: "center",
                    icon: isSuccess ? "success" : "error",
                    showConfirmButton: false,
                    timer: 2000,
                    title: isSuccess ? "Success" : "Error",
                    text: typeCheck === "edit" ? "Faq Updated Successfully" : "Faq Added Successfully",
                });
                if (isSuccess) {
                    loadFaqList();
                    resetForm();
                    setBusy(false);
                    setTypeCheck("add");
                } else {
                    resetForm();
                    setBusy(false);
                    setTypeCheck("add");
                }
            } catch (error) {
                console.error("Error:", error);
                resetForm();
                setBusy(false);
                setTypeCheck("add");
            }
        },
    });
    return (
        <Box
            sx={{
                padding: "0px 0px 0px 0px",
            }}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <CustomTextField
                            label="Question"
                            type="text"
                            placeholder="Enter Question"
                            name="question"
                            value={formik.values.question}
                            onBlur={formik.handleBlur}
                            onKeyUp={formik.handleBlur}
                            errors={formik.touched.question && formik.errors.question}
                            onChange={(e) => {
                                const { value } = e.target;
                                if (value.trimStart() !== value) {
                                    e.target.value = value.trimStart();
                                }
                                formik.handleChange(e);
                            }}
                            sx={{
                                "& .MuiInputBase-root": {
                                    borderRadius: "10px",
                                    border: formik.errors.question ? "1px solid red" : "1px solid #BB9EFF",
                                    color: "black",
                                    fontSize: "18px",
                                    background: "white",
                                    fontFamily: "Outfit",
                                },
                            }}
                            style={{ color: "black", fontSize: "18px" }}
                            isLoading={busy}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <CustomTextField
                            label="Answer"
                            type="text"
                            placeholder="Enter Answer"
                            name="answer"
                            multiline={true}
                            rows={3}
                            value={formik.values.answer}
                            onBlur={formik.handleBlur}
                            onKeyUp={formik.handleBlur}
                            errors={formik.touched.answer && formik.errors.answer}
                            onChange={(e) => {
                                const { value } = e.target;
                                if (value.trimStart() !== value) {
                                    e.target.value = value.trimStart();
                                }
                                formik.handleChange(e);
                            }}
                            sx={{
                                "& .MuiInputBase-root": {
                                    borderRadius: "10px",
                                    border: formik.errors.answer ? "1px solid red" : "1px solid #BB9EFF",
                                    color: "black",
                                    fontSize: "18px",
                                    background: "white",
                                    fontFamily: "Outfit",
                                },
                            }}
                            style={{ color: "black", fontSize: "18px" }}
                            isLoading={busy}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                            }}
                        >
                            <CustomSubmitButton
                                btnTitle={typeCheck === "edit" ? "Update Faq" : "Add Faq"}
                                loading={busy}
                                type="submit"
                                sx={{
                                    width: '170px'
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <Box
                sx={{
                    padding: "24px 0px 0px 0px",
                }}
            >
                <Grid container spacing={3}>
                    {faqList?.map((elem, index) => (
                        <Grid item xs={12} key={index}>
                            <Box
                                sx={{
                                    backgroundColor: "#ffffff",
                                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                    borderRadius: "6px",
                                    padding: "20px 10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "0px",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#000",
                                        fontFamily: "Poppins",
                                        paddingRight: "100px",
                                        margin: "0px",
                                    }}
                                >
                                    {elem?.question?.length > 95 ? `${elem.question.slice(0, 95)}...` : elem?.question}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gridGap: "5px",
                                    }}
                                >
                                    <IconButton
                                        className="editButton"
                                        onClick={() => handleEditHelpSupport(elem?.id)}
                                        sx={{
                                            outline: "none",
                                            border: "none",
                                            backgroundColor: "#ffffff",
                                            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Tooltip title='Edit'>
                                            <img src={EDIT_ICON} alt="edit" />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton
                                        className="deleteButton"
                                        onClick={() => handleDeleteHelpSupport(elem?.id)}
                                        sx={{
                                            outline: "none",
                                            border: "none",
                                            backgroundColor: "#ffffff",
                                            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Tooltip title='Delete'>
                                            <img src={DELETE_ICON} alt="delete" />
                                        </Tooltip>

                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default Faq;
