import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid, Typography } from "@mui/material";
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import useDebounce from '../../../Components/useDebounce/useDebounce';
import TableContainer from "../../../Components/TableContainer/TableContainer";
import PROFILE_PHOTO_USER from '../../../Assets/Images/Common/avtar.png'
import CustomAction from '../../../Components/CustomAction/CustomAction';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const UserManagement = () => {
  const pageSize = 30;
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const debouncedSearch = useDebounce(searchUser, 500);
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100, },
    {
      field: "profile_user",
      headerName: "Profile Photo",
      minWidth: 200,
    
      renderCell: (params) => {
        return (
          <img src={params?.row?.profile_photo ? params?.row?.profile_photo : PROFILE_PHOTO_USER} alt='profile_photo' style={{
            width: '40px',
            height: '40px',
            borderRadius: '10px'
          }} />
        )
      }
    },
    {
      field: "user_name",
      headerName: "User Name",
      minWidth: 300,

      renderCell: (params) => (params?.row?.user_name ? params?.row?.user_name : "--")
    },
    {
      field: "full_name",
      headerName: "Full Name",
      minWidth: 300,
      renderCell: (params) => {
        if (params?.row?.full_name) {
          return params.row.full_name;
        } else if (params?.row?.user_name) {
          return params.row.user_name;
        } else {
          return "--";
        }
      }
    },
    {
      field: "premium",
      headerName: "Premium",
      minWidth: 300,

      renderCell: (params) => {
        return (
          <Typography sx={{
            background: params?.row?.premium === "premium" ? '#D0BCFF' : 'transparent',
            padding: '10px 30px',
            borderRadius: '10px',
            fontSize: '16px',
            fontWeight: '400',
            color: '#000000',
            width: '100px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: "Outfit",
            border: params?.row?.premium !== "premium" ? '2px solid #D0BCFF' : "none"

          }}>{params?.row?.premium === "premium" ? "Premium" : "Free"}</Typography>
        )
      }
    },
    {
      field: "action",
      headerName: "Actions",
      flex:1,
      minWidth: 300,
      renderCell: (params) => {
        const userId = params?.row?.id;
        const currentStatus = params?.row?.status;
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", gap: "2rem" }}>
            <CustomAction
             showBlock={currentStatus === 1}
             showUnBlock={currentStatus === -1}
             handleBlock={handleBlockUnBlockStatusChange}
             handleUnBlock={handleBlockUnBlockStatusChange}
             userId={userId}
             currentStatus={currentStatus}
            />
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    getUser();
  }, [debouncedSearch,page])
  const getUser = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(APILIST.GET_USERS, {
        params: {
          count: page * pageSize,
          offset: pageSize,
          search: debouncedSearch
        }
      });
      if (response && response?.data?.s) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setUserList(Rowdata);
        setCount(response?.data?.c || 0);
      } else {
        setUserList([]);
      }
    } catch (error) {
      console.log('error', error);
    } finally{
      setIsLoading(false);
    }
  }
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleBlockUnBlockStatusChange = async (userId, currentStatus) => {
    const newStatus = currentStatus === 1 ? -1 : 1;
    try {
      const formData = new FormData();
      formData.append('user_id', userId);
      formData.append('status', newStatus);
  
      const response = await AxiosInstance.post(APILIST.UPDATE_ACCOUNT_USERS, formData);
  
      if (response && response.data.s) {
        toast.success(`User ${newStatus === 1 ? 'unblocked' : 'blocked'} successfully.`);
        getUser();
      } else {
        toast.error('Failed to update status. Please try again.');
      }
    } catch (error) {
      console.log('error', error);
      toast.error('An error occurred while updating status.');
    }
  }
  return (
    <Fragment>
      <Box sx={{
        width: '100%',
        padding: '15px'
      }}>
        <Typography sx={{ fontFamily: "Outfit",fontSize: { xs: "20px", md: "32px" }, fontWeight: "500", paddingBottom: '10px', color: '#000000' }}>
          User Management
        </Typography>
        <Box sx={{ padding: "24px 0px 24px 0px", borderTop: '1px solid #0000003D' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={8}>
              <CustomSearch
                placeholder="Search User"
                value={searchUser}
                onChange={(event) => setSearchUser(event.target.value)}
                onClear={() => setSearchUser("")}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            </Grid>
          </Grid>
          <Box sx={{ padding: "24px 0px 24px 0px" }}>
          <TableContainer
              rows={userList}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              page={page}
              totalCount={count}
              handlePageChange={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default UserManagement;
