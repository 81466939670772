import React, { Fragment, useState } from "react";
import { Autocomplete, TextField, Chip, Typography, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AxiosInstance from "../../Config/AxiosInstance";
import APILIST from "../../Config/ApiList";
import { toast } from "react-toastify"; // Assuming you're using toast for notifications
import Skeleton from "react-loading-skeleton";

const ChipMultiSelect = ({ selectedOptions, onChange, defaultOptions, name, isLoading,errorss, onBlur,onKeyUp }) => {
    const [loading, setLoading] = useState(false);
    const validSelectedOptions = Array.isArray(selectedOptions) ? selectedOptions : [];
    const [errors, setErrors] = useState(false); // Manage errors in state

    // Handle deleting a chip locally
    const handleDelete = (chipToDelete) => {
        const updatedValues = validSelectedOptions.filter(
            (chip) => chip.id !== chipToDelete.id
        );
        onChange(updatedValues);
    };

    // Handle deleting a tag from the server
    const handleDeleteTags = async (data) => {
        setLoading(true); // Show loading when deleting tag
        try {
            const formData = new FormData();
            formData.append("id", data?.id);
            formData.append("tag", data?.tag);
            formData.append("status", 0);

            const response = await AxiosInstance.post(APILIST.DELETE_TAGS_BLOG, formData);

            if (response?.data?.s) {
                // Update tags if deletion is successful
                toast.success("Tag deleted successfully");
                handleDelete(data); // Also remove it from the local state
            } else {
                throw new Error("Failed to delete tag");
            }
        } catch (error) {
            toast.error("Failed to delete tag, please try again.");
            setErrors(true); // Set error state
        } finally {
            setLoading(false); // Stop loading after delete
        }
    };

    return (
        <Fragment>
            <Typography
                sx={{
                    color: "#000",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: { xs: "16px", sm: "18px" },
                    fontFamily: "Outfit",
                    mb: "10px",
                }}
            >
                Select Tags
            </Typography>
            {isLoading ? (
                <Skeleton height={"60px"} />
            ) : (<Autocomplete
                multiple
                freeSolo
                name={name}
                id="chip-multiple-select"
                options={defaultOptions}
                getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.tag
                }
                value={validSelectedOptions}
                onChange={(event, newValue) => {
                    const updatedValues = newValue.map((item, index) =>
                        typeof item === "string" ? { id: `${item}-${index}`, tag: item } : item
                    );
                    onChange(updatedValues);
                }}
                onBlur={onBlur}
                onKeyUp={onKeyUp}
                sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                        outline: "none",
                        border: errorss ? "1px solid red" : "1px solid #BB9EFF",
                    },
                    "& .MuiInputBase-root": {
                        borderRadius: "10px",
                        border: errorss ? "1px solid red" : "none",
                        color: "#000",
                        fontSize: "16px",
                        background: "transparent",
                        fontFamily: "Outfit",
                    },
                }}
                clearIcon={null}
                renderTags={(value) =>
                    value?.map((option, index) => (
                        <Chip
                            key={typeof option === "string" ? index : option.id}
                            variant="outlined"
                            label={typeof option === "string" ? option : option.tag}
                            onDelete={() =>
                                option?.status
                                    ? handleDeleteTags(option)
                                    : handleDelete(option)
                            }
                            deleteIcon={
                                loading ? <CircularProgress size={20} /> : <CloseIcon />
                            }
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select or type tags..."
                        error={errors}
                        helperText={errors && "Please select valid tags."}
                    />
                )}
            />)}
            {errorss && (
          <Typography
            sx={{
              color: "red",
              fontWeight: "400",
              fontSize: { xs: "14px", sm: "14px" },
              marginTop: "5px",
            }}
          >
            {errorss}
          </Typography>
        )}
        </Fragment>
    );
};

export default ChipMultiSelect;
