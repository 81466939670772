import { Box, Dialog, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import DUMMAYPROFILE from '../../../Assets/Images/Common/avatar-5.jpg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';

import { deleteDoc, doc } from 'firebase/firestore';
import { DATABASE } from '../../../Config/firebase';
import ReplyUserDetails from './ReplyUserDetails';
import CloseIcon from '@mui/icons-material/Close';
const UserDetails = ({ messages, selectedChat, setReplyMessage }) => {
    const endOfMessagesRef = useRef(null);
    const [showFullImageModel, setShowFullImageModel] = useState(false);
    const [showFullImagePrev, setShowFullImagePrev] = useState(null);
    const [menuAnchorEls, setMenuAnchorEls] = useState({});
    const handleClick = (event, messageId) => {
        setMenuAnchorEls(prev => ({ ...prev, [messageId]: event.currentTarget }));
    };

    const handleClose = (messageId) => {
        setMenuAnchorEls(prev => ({ ...prev, [messageId]: null }));
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date((timestamp.seconds * 1000) + (timestamp.nanoseconds / 1000000));
        return moment(date).format('hh:mm a');
    };
    const getReferencedMessage = (referenceId) => {
        return messages.find(msg => msg.id === referenceId);
    };

    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleDelete = async (messageId) => {
        try {
            await deleteDoc(doc(DATABASE, 'support', selectedChat?.id, "support_chat", messageId));
        } catch (error) {
            console.log(error);
        }
    };

    const handleReply = (messageId) => {
        const selectedMessage = messages.find((message) => message.id === messageId);
        if (selectedMessage) {
            setReplyMessage(selectedMessage);
            handleClose(messageId);
        }
    };
    const handleOpenImageModel = (img) => {
        setShowFullImageModel(true);
        setShowFullImagePrev(img || null)
    }
    
    return (
        <Fragment>
            <Box sx={{
                width: '100%',
                height: "calc(100vh - 81px)",
                overflowY: 'auto',
                padding: '85px 20px 0px 20px',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent:'flex-end',
                gridGap: '24px',
            }}>
                {messages?.length > 0 && messages.map((msg, index) => {
                    const referencedMessage = msg?.reference_chat_id ? getReferencedMessage(msg.reference_chat_id) : null;

                    return (
                        <Fragment key={index}>
                            {msg?.isSent ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '5px',
                                        width: '100%',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        gridGap: '10px',
                                        position: 'relative',
                                        '&:hover .more-options': {
                                            opacity: 1,
                                            visibility: 'visible',
                                        },
                                    }}>
                                        {msg?.msg &&
                                            <Box sx={{
                                                padding: '15px',
                                                borderRadius: '20px 20px 0px 20px', background: '#EFE9FF',
                                            }}>
                                                {msg?.reference_chat_id && (
                                                    <ReplyUserDetails
                                                        referencedMessage={referencedMessage}
                                                        selectedChatId={selectedChat?.id}
                                                        id={msg.reference_chat_id}
                                                        messagesRe={messages}
                                                    />
                                                )} <Typography
                                                    sx={{
                                                        fontSize: '18px',
                                                        // padding: '15px',
                                                        borderRadius: '20px 20px 0px 20px',
                                                        wordBreak: 'break-word',
                                                        background: '#EFE9FF',
                                                        color: '#000',
                                                        fontWeight: '400',
                                                        maxWidth: '700px'
                                                    }}
                                                >
                                                    {msg?.msg}
                                                </Typography>
                                                {/* {msg?.date && (
                                                <Typography
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '500',
                                                        color: '#00000099',
                                                        textAlign: 'right',
                                                        mt: '5px'
                                                    }}
                                                >
                                                    {formatTimestampDate(msg.date)}
                                                </Typography>
                                            )} */}
                                            </Box>}
                                        {msg?.imageUrl && (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }} onClick={() => handleOpenImageModel(msg?.imageUrl)}>
                                                <img
                                                    src={msg?.imageUrl}
                                                    alt="Uploaded"
                                                    style={{
                                                        maxWidth: '100px',
                                                        height: '100px',
                                                        borderRadius: '8px',
                                                        marginBottom: '5px',
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        <Box sx={{
                                            position: 'relative',
                                            width: '26px',
                                            height: '26px',
                                            borderRadius: '50%',
                                        }}>
                                            <img
                                                src={DUMMAYPROFILE}
                                                alt="Uploaded"
                                                style={{
                                                    width: '26px',
                                                    height: '26px',
                                                    borderRadius: '50%'
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            className="more-options"
                                            sx={{
                                                opacity: 0,
                                                visibility: 'hidden',
                                                transition: 'opacity 0.3s ease, visibility 0.3s ease',
                                                position: 'absolute',
                                                bottom: '30px',
                                                right: '2px',
                                                zIndex: 1,
                                            }}
                                        >
                                            <Tooltip title="Action">
                                                <IconButton
                                                    size="small"
                                                    aria-haspopup="true"
                                                    onClick={(event) => handleClick(event, msg.id)}
                                                >
                                                    <MoreVertIcon sx={{ fontSize: '18px' }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                anchorEl={menuAnchorEls[msg.id]}
                                                open={Boolean(menuAnchorEls[msg.id])}
                                                onClose={() => handleClose(msg.id)}
                                                MenuListProps={{
                                                    'aria-labelledby': 'more-options',
                                                }}
                                            >
                                                <MenuItem onClick={() => handleDelete(msg.id)}>Delete</MenuItem>
                                                <MenuItem onClick={() => handleReply(msg.id)}>Reply</MenuItem>
                                            </Menu>
                                        </Box>
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            color: '#00000099',
                                            textAlign: 'right',
                                            paddingRight: '36px',
                                        }}
                                    >
                                        {formatTimestamp(msg?.date)}
                                    </Typography>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '5px',
                                        width: '100%',
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        gridGap: '10px',
                                        position: 'relative',
                                        '&:hover .more-options': {
                                            opacity: 1,
                                            visibility: 'visible',
                                        },
                                    }}>
                                        <Box sx={{
                                            position: 'relative',
                                            width: '26px',
                                            height: '26px',
                                            borderRadius: '50%',
                                        }}>
                                            <img
                                                src={DUMMAYPROFILE}
                                                alt="Uploaded"
                                                style={{
                                                    width: '26px',
                                                    height: '26px',
                                                    borderRadius: '50%'
                                                }}
                                            />
                                        </Box>
                                        {msg?.imageUrl && (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px', cursor:'pointer' }} onClick={() => handleOpenImageModel(msg?.imageUrl)}>
                                                <img
                                                    src={msg?.imageUrl}
                                                    alt="Uploaded"
                                                    style={{
                                                        maxWidth: '200px',
                                                        borderRadius: '8px',
                                                        height: '200px',
                                                        marginBottom: '5px',
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {msg?.msg && <Typography
                                            sx={{
                                                fontSize: '18px',
                                                padding: '15px',
                                                borderRadius: '20px 20px 20px 0px',
                                                wordBreak: 'break-word',
                                                background: 'linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)',
                                                color: '#000',
                                                fontWeight: '400',
                                                maxWidth: '700px'
                                            }}
                                        >
                                            {msg?.msg}
                                        </Typography>}
                                        <Box
                                            className="more-options"
                                            sx={{
                                                opacity: 0,
                                                visibility: 'hidden',
                                                transition: 'opacity 0.3s ease, visibility 0.3s ease',
                                                position: 'absolute',
                                                bottom: '30px',
                                                left: '2px',
                                                zIndex: 1,
                                            }}
                                        >
                                            <Tooltip title="Action">
                                                <IconButton
                                                    size="small"
                                                    aria-haspopup="true"
                                                    onClick={(event) => handleClick(event, msg.id)}
                                                >
                                                    <MoreVertIcon sx={{ fontSize: '18px' }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                anchorEl={menuAnchorEls[msg.id]}
                                                open={Boolean(menuAnchorEls[msg.id])}
                                                onClose={() => handleClose(msg.id)}
                                                MenuListProps={{
                                                    'aria-labelledby': 'more-options',
                                                }}
                                            >
                                                {msg?.isSent && (
                                                    <MenuItem onClick={() => handleDelete(msg.id)}>Delete</MenuItem>
                                                )}
                                                <MenuItem onClick={() => handleReply(msg.id)}>Reply</MenuItem>
                                            </Menu>
                                        </Box>
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            color: '#00000099',
                                            textAlign: 'left',
                                            paddingLeft: '36px'
                                        }}
                                    >
                                        {formatTimestamp(msg.date)}
                                    </Typography>
                                </Box>
                            )}
                        </Fragment>
                    );
                })}
                <div ref={endOfMessagesRef} />
            </Box>
            <Dialog
                open={showFullImageModel}
                onClose={() => setShowFullImageModel(false)}
                maxWidth="sm"
                fullWidth
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "20px",
                        padding: '40px 40px 40px 40px ',
                        height: "400px",
                        overflow: "scroll",
                        "::-webkit-scrollbar": { width: "0px", height: '0px' }
                    },
                    "& .MuiDialogContent-root": {
                        overflowY: "initial",
                    },
                    "& .MuiBackdrop-root": {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    }
                }}
            >
                <Box sx={{
                    // position:'relative',
                    // backgroundColor:'red'
                    height:'100%'
                }}>
                    <img src={showFullImagePrev} alt='prevImage' style={{
                        width:'100%',
                        height:'100%',
                        objectFit:'cover'
                    }}/>
                    <IconButton onClick={() => setShowFullImageModel(false)} sx={{
                        width:'30px',
                        height:'30px',
                        borderRadius:'50%',
                        backgroundColor:'red',
                        position:'absolute',
                        top:'10px',
                        right:'10px',
                        zIndex:"99",
                        "&:hover":{
                            backgroundColor:'red',
                        }
                    }}>
                        <CloseIcon style={{
                            color:'#fff'
                        }}/>
                    </IconButton>
                </Box>
            </Dialog>
        </Fragment>

    );
};

export default UserDetails;
