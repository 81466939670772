import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { DATABASE } from '../../../Config/firebase';
import ReplyIcon from '@mui/icons-material/Reply';
const ReplyUserDetails = ({ id, selectedChatId, messagesRe }) => {
    const [message, setMessage] = useState(null);
    useEffect(() => {
        const fetchMessage = async () => {
            if (id && selectedChatId) {
                try {
                    const docRef = doc(DATABASE, "support", selectedChatId, "support_chat", id);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setMessage(docSnap.data());
                    } else {
                        console.log('No such document!');
                    }
                } catch (error) {
                    console.error("Error fetching document: ", error);
                }
            }
        };
        fetchMessage();
    }, [id, selectedChatId]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-end',
            paddingRight: '36px',
            // margin: '10px 0px'
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                gridGap: '10px',
                position: 'relative',
                '&:hover .more-options': {
                    opacity: 1,
                    visibility: 'visible',
                },
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    width: '100%',
                    alignItems: 'flex-end',
                }}>
                    <Box sx={{
                        fontSize: '18px',
                        // padding: '15px',
                        borderRadius: '20px 20px 0px 20px',
                        wordBreak: 'break-word',
                        background: '#EFE9FF',
                        color: '#000',
                        fontWeight: '400',
                        maxWidth: '300px',
                        position: 'relative',
                    }}>
                        <ReplyIcon sx={{ fontSize: '32px', verticalAlign: 'middle', mr: '8px' }} />
                        {messagesRe && <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#00000099',
                                textAlign: 'right',
                                mt: '10px'
                            }}
                        >
                            {messagesRe?.msg}
                        </Typography>}
                        {message?.msg && <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#00000099',
                                textAlign: 'right',
                                mt: '10px'
                            }}
                        >
                            {message?.msg}
                        </Typography>}
                        {message?.imageUrl && <Box sx={{ mt: '10px' }}>
                            <img src={message?.imageUrl} alt='replyImage' style={{
                                maxWidth: '100px',
                                borderRadius: '8px',
                                height: '100px',
                                marginBottom: '5px',
                            }} />
                        </Box>}
                        
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ReplyUserDetails;
