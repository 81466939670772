import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import CustomDateTimePicker from "../../../Components/CustomDatePicker/CustomDatePicker";
import CustomSubmitButton from "../../../Components/CustomSubmitButton/CustomSubmitButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import Constant from "../../../Utils/Constant";
import CUstomTimePicker from "../../../Components/CustomTimePicker/CustomTimePicker";
const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [optionList, setOptionList] = useState([
    {
      id: 1,
      name: "Send Now",
    },
    {
      id: 2,
      name: "Schedule Later",
    },
  ])
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      selectOption: 0,
      selectDate: null,
      selectTime: null,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required("Please enter notification title")
        .matches(Constant.REGEX.NAME, "Please enter valid title"),
      description: Yup.string().required("Please enter description here"),
      selectOption: Yup.mixed()
        .test(
          "is-not-zero",
          "This field is required!",
          (value) => value !== 0
        )
        .required("Select option is required!"),
      selectDate: Yup.mixed().required("Please enter date here"),
      selectTime: Yup.mixed().required("Please enter time here"),
    }),
    onSubmit: async (values, { resetFrom }) => { },
  });
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
        }}
      >
        <Box>
          <Typography
            sx={{ fontSize: { xs: "25px", md: "30px" }, fontWeight: "500" }}
          >
            Notifications
          </Typography>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              width: { xs: "100%", lg: "50%" },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  label={"Notification Title"}
                  name="title"
                  value={formik.values.title}
                  onKeyUp={formik.handleBlur}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.touched.title && formik.errors.title}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "10px",
                      border: formik.errors.title ? "1px solid red" : "1px solid #BB9EFF",
                      color: "black",
                      fontSize: "18px",
                      background: "white",
                      fontFamily: "Outfit",
                    },
                  }}
                  style={{ color: "black", fontSize: "18px" }}
                  placeholder={"Title"}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="description"
                  value={formik.values.description}
                  onKeyUp={formik.handleBlur}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={formik.touched.description && formik.errors.description}
                  label={"Description"}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "10px",
                      border: formik.errors.description ? "1px solid red" : "1px solid #BB9EFF",
                      color: "black",
                      fontSize: "18px",
                      background: "white",
                      fontFamily: "Outfit",
                    },
                  }}
                  style={{ color: "black", fontSize: "18px" }}
                  placeholder={"Description"}
                  multiline={true}
                  rows={5}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomDropDown
                  Title={"Select Option"}
                  value={formik.values.selectOption}
                  onChange={formik.handleChange}
                  placeholder={'Select Option'}
                  menuList={optionList}
                  NoData='No Option Available'
                  isLoading={isLoading}
                  errors={formik.touched.selectOption && formik.errors.selectOption}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: { xs: "16px", sm: "18px" },
                    mb:'8px'
                  }}
                >
                  Date
                </Typography>
                <CustomDateTimePicker
                  name="selectDate"
                  value={formik.values.selectDate}
                  onChange={(newValue) =>
                    formik.setFieldValue("selectDate", newValue)
                  }
                  errors={
                    formik.touched.selectDate && formik.errors.selectDate
                  }
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    color: "black",
                    fontSize: "18px",
                    background: "white",
                    fontFamily: "Outfit",
                    outline:
                      formik.touched.selectTime && formik.errors.selectTime
                        ? "1px solid red"
                        : "1px solid #D0BCFF",
                    ".MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      border: "none",
                    },
                  }}
                  isLoading={isLoading}
                />
                {formik.touched.selectDate && formik.errors.selectDate ? (
                  <Typography
                    sx={{
                      color: "red",
                      fontWeight: "400",
                      fontSize: { xs: "14px", sm: "14px" },
                      marginTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    {formik.errors.selectDate}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: { xs: "16px", sm: "18px" },
                    mb:'8px'
                  }}
                >
                  Time
                </Typography>
                {/* <CustomDateTimePicker
                  name="selectTime"
                  value={formik.values.selectTime}
                  onChange={(newValue) =>
                    formik.setFieldValue("selectTime", newValue)
                  }
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    color: "black",
                    fontSize: "18px",
                    background: "white",
                    fontFamily: "Outfit",
                    outline:
                      formik.touched.selectTime && formik.errors.selectTime
                        ? "1.5px solid red"
                        : "1.5px solid #D0BCFF",
                    ".MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  isLoading={isLoading}
                /> */}
                <CUstomTimePicker sx={{
                    width: "100%",
                    borderRadius: "10px",
                    color: "black",
                    fontSize: "18px",
                    background: "white",
                    fontFamily: "Outfit",
                    outline:
                      formik.touched.selectTime && formik.errors.selectTime
                        ? "1px solid red"
                        : "1px solid #D0BCFF",
                    ".MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      border: "none",
                    },
                  }}/>
                {formik.touched.selectTime && formik.errors.selectTime ? (
                  <Typography
                    sx={{
                      color: "red",
                      fontWeight: "400",
                      fontSize: { xs: "14px", sm: "14px" },
                      marginTop: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    {formik.errors.selectTime}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSubmitButton
                  type={"submit"}
                  sx={{
                    width: { xs: "70%", md: "50%" },
                    color: "black",
                    "&:hover": { color: "black" },
                  }}
                  btnTitle={"Send"}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Notifications;
