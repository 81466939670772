import { Box } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';

const CustomEmoji = ({ onEmojiClick }) => {
    return (
        <Box sx={{
            position: 'absolute',
            bottom: '70px',
            zIndex: 10000,
            left: 0,
            width: '300px',
        }}>
            <EmojiPicker height={"350px"} onEmojiClick={onEmojiClick} />
        </Box>
    );
}

export default CustomEmoji;
