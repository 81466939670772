
import React, { Fragment, useState, useEffect } from 'react';
import { Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button } from '@mui/material';
import AddWorkOutModel from './AddWorkOutModel';
import CustomAction from '../../../Components/CustomAction/CustomAction';
import { toast } from 'react-toastify';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import Swal from 'sweetalert2';
import ShowFullScreenVideo from './ShowFullScreenVideo';

const PlanWorkOutList = (props) => {
    const {
        isSelectedDays,
        isSelectedDay,
        isSelectedRunnerPlanDetails,
        handleWorkOutOnSubmit,
        openAddWorkOutModel,
        setOpenAddWorkOutModel,
        onDeleteUpdate,
        handleDeletePlandetailsDayAllPosition
    } = props;

    const [addWorkOutPlanCards, setAddWorkOutPlanCards] = useState(JSON.parse(window.sessionStorage.getItem('planDay')) || []);
    const [isSelectWorkOutCardPostion, setIsSelectWorkOutPostion] = useState(null);
    const [editPlanWorkOutDetails, setEditPlanWorkoutDetails] = useState({});
    const [openFullScreenVideoModel, setOpenFullScreenVideoModel] = useState(false);
    const [openFullScreenVideoModelData, setOpenFullScreenVideoModelData] = useState({});
    useEffect(() => {
        if (isSelectedDays) {
            setAddWorkOutPlanCards(isSelectedDays);
        }
    }, [isSelectedDays]);

    const handleAddWorkOut = (position) => {
        const day = Object.keys(position)[0];
        setEditPlanWorkoutDetails({});
        setOpenAddWorkOutModel(true);
        setIsSelectWorkOutPostion(day);
    };

    const shouldShowAddWorkout = (position, day) => {
        const workoutsForDay = isSelectedRunnerPlanDetails.filter(
            workout => workout.position === position && workout.day == day
        );
        return workoutsForDay.length === 0;
    };

    const filteredPlanDays = Array.from(new Set(addWorkOutPlanCards)).sort((a, b) => a - b);
    const handleDeletePlanDetails = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this plan details",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#46adf2",
            cancelButtonColor: "#5b5d5d",
            confirmButtonText: "Yes, delete it",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const params = {
                    id: id,
                    status: 0,
                };
                try {
                    const response = await AxiosInstance.post(
                        APILIST.UPDATE_DELETE_PLAN_DETAILS,
                        params
                    );
                    if (response?.data?.s === 1) {
                        toast.success("Plan Details Deleted Sucessfully !.");
                        onDeleteUpdate()
                    }
                } catch (error) {
                    toast.error("Failed to delete plan details . Please try again.");
                }
            }
        });
    };
    const handleEditPlanDetails = (data) => {
        setOpenAddWorkOutModel(true);
        if (data?.id) {
            setEditPlanWorkoutDetails(data);
        }
    }
    const handleFullScreenVideoShow = async (id) => {
        try {
            const response = await AxiosInstance.get(APILIST.GET_VIDEOS);
            if (response && response?.data?.s) {
                const rowData = response?.data?.r?.find((elem) => elem?.id === id);
                setOpenFullScreenVideoModel(true)
                setOpenFullScreenVideoModelData(rowData)
            } else {
                setOpenFullScreenVideoModel(false);
            }
        } catch (error) {
            console.log('error', error);
        }
    }
    return (
        <Fragment>
            {isSelectedDays && filteredPlanDays && (
                <Box sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: '15px', padding: '20px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ color: 'black', fontWeight: '400', textAlign: 'left', fontSize: { xs: '16px', sm: '18px' }, mb: '8px' }}>
                            Plan For {filteredPlanDays.length} {filteredPlanDays.length > 1 ? 'days' : 'day'}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {isSelectedRunnerPlanDetails?.filter(workout => workout?.day == isSelectedDay)?.length > 0 && (
                                <Button sx={{
                                    width: '70px',
                                    background: "rgba(204, 51, 0, 1)",
                                    color: '#fff',
                                    fontSize: '10px',
                                    fontWeight: '400',
                                    borderRadius: '8px',
                                    mb: "10px",
                                    "&:hover": {
                                        background: "rgba(204, 51, 0, 1)",
                                        color: '#fff',
                                    }
                                }} onClick={() => handleDeletePlandetailsDayAllPosition(isSelectedDay)}>
                                    Delete
                                </Button>
                            )}
                        </Box>

                    </Box>
                    <Grid container spacing={3}>

                        {filteredPlanDays.map((elem, index) => {
                            const day = Object.keys(elem)[0];
                            const workoutsForDay = isSelectedRunnerPlanDetails.filter(
                                workout => workout.position == day && workout.day == isSelectedDay
                            );
                            const showAddWorkout = shouldShowAddWorkout(day, isSelectedDay);
                            return (
                                <Grid item xs={12} key={index}>

                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: '10px'
                                    }}>
                                        <Typography sx={{ color: '#B190FF', fontWeight: '400', textAlign: 'left', fontSize: { xs: '16px', sm: '16px' }, mb: '10px' }}>
                                            {`Workout day ${day}`}
                                        </Typography>
                                        {workoutsForDay?.length > 0 ? <Button sx={{
                                            color: '#fff',
                                            background: "#B190FF",
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            borderRadius: '8px',
                                            "&:hover": {
                                                color: '#fff',
                                                background: "#B190FF",
                                            }
                                        }} onClick={() => handleAddWorkOut({ [day]: [] })}>Add More</Button> : null}
                                    </Box>
                                    {workoutsForDay?.length > 0 ? (
                                        <>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="workout table">
                                                    <TableBody>
                                                        {workoutsForDay?.map((workout, workoutIndex) => (
                                                            <TableRow key={workoutIndex}>
                                                                <TableCell component="th" scope="row">{workoutIndex + 1}</TableCell>
                                                                <TableCell component="th" scope="row">{workout?.name}</TableCell>
                                                                <TableCell align="right">{workout?.value}</TableCell>
                                                                <TableCell align="right">{workout?.type === 1 ? "Min" : "Mile"}</TableCell>
                                                                <TableCell align="right">
                                                                    {workout?.training_video_id ? (
                                                                        <Typography
                                                                            sx={{ textDecoration: "underline", color: '#000000', cursor: 'pointer' }}
                                                                            onClick={() => handleFullScreenVideoShow(workout?.training_video_id)}>
                                                                            Video
                                                                        </Typography>
                                                                    ) : "--"}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <CustomAction
                                                                        showDelete={true}
                                                                        showEdit={true}
                                                                        isDelete={() => handleDeletePlanDetails(workout?.id)}
                                                                        isEdit={() => handleEditPlanDetails(workout)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>

                                    ) : showAddWorkout && (
                                        <Box
                                            sx={{
                                                borderRadius: '20px',
                                                border: '1px solid #D0BCFF',
                                                height: '100px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                mt: workoutsForDay.length > 0 ? 2 : 0
                                            }}
                                            onClick={() => handleAddWorkOut({ [day]: [] })}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#B190FF',
                                                    fontWeight: '400',
                                                    fontSize: { xs: '16px', sm: '18px' },
                                                    '&:hover': { textDecoration: 'underline' },
                                                }}
                                            >
                                                Add Workout
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            )}
            <AddWorkOutModel
                open={openAddWorkOutModel}
                onClose={() => { setOpenAddWorkOutModel(false); setEditPlanWorkoutDetails({}) }}
                handleOnClose={() => { setOpenAddWorkOutModel(false); setEditPlanWorkoutDetails({}) }}
                handleWorkOutOnSubmit={handleWorkOutOnSubmit}
                isSelectWorkOutCardPostion={isSelectWorkOutCardPostion}
                editPlanWorkOutDetails={editPlanWorkOutDetails}
            />
            <ShowFullScreenVideo
                open={openFullScreenVideoModel}
                onClose={() => {
                    setOpenFullScreenVideoModelData({});
                    setOpenFullScreenVideoModel(false)
                }}
                handleOnClose={() => {
                    setOpenFullScreenVideoModelData({});
                    setOpenFullScreenVideoModel(false)
                }}
                openFullScreenVideoModelData={openFullScreenVideoModelData} />
        </Fragment>
    );
};

export default PlanWorkOutList;

