import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Tooltip, Typography } from "@mui/material";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import PROFILE_PHOTO_USER from '../../../Assets/Images/Common/avtar.png'
import CustomAction from '../../../Components/CustomAction/CustomAction';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import AddVideoModel from './AddVideoModel';
import Constant from '../../../Utils/Constant';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
const VideoManagement = () => {
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [typeCheck, setTypeCheck] = useState(null);
  const [showVideoModel, setShowVideoModel] = useState(false);
  const [editVideoId, setEditVideoId] = useState(null);
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100, },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <img src={params?.row?.thumb ? `${Constant.BASE_URL}${params?.row?.thumb}` : PROFILE_PHOTO_USER} alt='profile_photo' style={{
            width: '40px',
            height: '40px',
            borderRadius: '10px'
          }} />
        )
      }
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 300,

      renderCell: (params) => (params?.row?.title ? params?.row?.title : "--")
    },
    {
      field: "category_name",
      headerName: "Category Name",
      minWidth: 300,

      renderCell: (params) => (params?.row?.category_name ? params?.row?.category_name : "--")
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 400,
      renderCell: (params) => {
        const description = params?.row?.description || '--';
        const previewText = description.length > 40 ? description.substring(0, 40) + '...' : description;
        return (
          <Tooltip title={description} arrow>
            <span>{previewText}</span>
          </Tooltip>
        );
      },
    },
  {
    field: "action",
    headerName: "Actions",
    flex: 1,
    minWidth: 300,
    renderCell: (params) => {
      return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", gap: "2rem" }}>
          <CustomAction
            showDelete={true}
            showEdit={true}
            isEdit={() => {
              handleEditVideoModel(params?.row?.id)
            }}
            isDelete={() => handleDeleteVideo(params?.row?.id)}
          />
        </Box>
      );
    },
    },
  ];
  useEffect(() => {
    getVideo();
  }, [page]);
const getVideo = async () => {
  setIsLoading(true);
  try {
    const response = await AxiosInstance.get(APILIST.GET_VIDEOS, {
      params: {
        count: page * pageSize,
        offset: pageSize,
      }
    });
    if (response && response?.data?.s) {
      const Rowdata = response?.data?.r?.map((data, i) => ({
        rowid: i + 1 + page * pageSize,
        ...data,
      }));
      setVideoList(Rowdata);
      setCount(response?.data?.c || 0);
    } else {
      setVideoList([]);
    }
  } catch (error) {
    console.log('error', error);
  } finally {
    setIsLoading(false);
  }
}
const handlePageChange = (newPage) => {
  setPage(newPage);
};
const handleEditVideoModel = (videoId) => {
  setTypeCheck('EDIT');
  setShowVideoModel(true);
  setEditVideoId(videoId)
}
const handleOnSubmit = () => {
  setTypeCheck(null);
  getVideo();
  setShowVideoModel(false);
}
const handleDeleteVideo = async (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this Video",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#46adf2",
    cancelButtonColor: "#5b5d5d",
    confirmButtonText: "Yes, delete it",
    cancelButtonText: "Cancel",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const params = {
        id: id,
        status: 0,
      };
      try {
        const response = await AxiosInstance.post(
          APILIST.DELETE_VIDEO,
          params
        );
        if (response?.data?.s === 1) {
          getVideo();
          toast.success("Video Deleted Sucessfully !.");
        }
      } catch (error) {
        toast.error("Failed to delete video. Please try again.");
      }
    }
  });
};
return (
  <Fragment>
    <Box sx={{
      width: '100%',
      padding: '15px'
    }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingBottom: '16px'
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "20px", md: "30px" },
            fontWeight: "500",
            paddingBottom: "10px",
            fontFamily: "Outfit",
          }}
        >
          Video Management
        </Typography>
        <Button
          sx={{
            width: "120px",
            borderRadius: "15px",
            textTransform: "none",
            fontSize: "16px",
            background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
            color: "black",
            padding: "0",
            margin: "0",
            height: '51px',
            "&:hover": {
              background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
              color: "black",
            },
            fontFamily: "Outfit",
          }}
          onClick={() => setShowVideoModel(true)}
        >
          Add Video
        </Button>
      </Box>
      <Box sx={{ padding: "24px 0px 24px 0px", borderTop: '1px solid #0000003D' }}>
        <Box sx={{ padding: "24px 0px 24px 0px" }}>
          <TableContainer
            rows={videoList}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            totalCount={count}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
    </Box>
    <AddVideoModel
      open={showVideoModel}
      onClose={() => { setShowVideoModel(false); setTypeCheck(null) }}
      typeCheck={typeCheck}
      editVideoId={editVideoId}
      handleOnSubmit={handleOnSubmit}
      handleOnClose={() => { setShowVideoModel(false); setTypeCheck(null) }}
    />
  </Fragment>
);
};

export default VideoManagement;
