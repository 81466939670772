import { Button, CircularProgress } from "@mui/material";
import React from "react";

const CustomSubmitButton = (props) => {
    const { type, onSubmit, btnTitle, loading, sx = {} , disabled} = props
    return (
        <>
            <Button
                sx={{
                    width: "100%",
                    borderRadius: "15px",
                    background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                    padding: "8px 12px",
                    fontWeight: "500",
                    fontSize: "18px",
                    color: "white",
                    textTransform: "capitalize",
                    "&:hover": {
                        background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                        color: "white",
                    },
                    "&:disabled":{
                        background: "#ddd",
                        color: "white",
                    },
                    ...sx,
                    fontFamily: "Outfit",
                }}
                type={type}
                onClick={onSubmit}
                disabled={disabled}
            >
                {loading ? (
                    <CircularProgress
                        size={32}
                        sx={{
                            color: "#fff",
                        }}
                    />
                ) : (
                    btnTitle
                )}
            </Button>
        </>
    );
};

export default CustomSubmitButton;
