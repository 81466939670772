import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import { adminLayoutRoute } from "../../Routes/Routes";
const AdminLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const userCheck = JSON.parse(localStorage.getItem("TRANSFORMATIONAL_RUNNER"));
    if (userCheck?.role !== Number(2)) {
      navigate("/login");
    }
  }, []);
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Routes>
        {adminLayoutRoute.map((route, index) => {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              element={
                route.sidebar ? (
                  <>
                    <Sidebar />
                    <Box
                      sx={{
                        width: "calc(100% - 280px)",
                        height: "100vh",
                        overflow: "auto",
                      }}
                    >
                      {route.component}
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{ width: "100%", height: "100vh", overflow: "auto" }}
                  >
                    {route.component}
                  </Box>
                )
              }
            />
          );
        })}
      </Routes>
    </Box>
  );
};
export default AdminLayout;
