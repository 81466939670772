import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { Fragment, useEffect, useRef } from 'react';
import EMOJIICON from '../../../Assets/Images/Common/emoji.png';
import CustomEmoji from '../../../Components/CustomEmoji/CustomEmoji';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import UserUploadImage from './UserUploadImage';
import CloseIcon from '@mui/icons-material/Close';

const UserSendTextField = (props) => {
    const { disabled, handleImageDelete, onKeyDown, selectedChat, uploadImageModel, replyMessage, onCloseReply, setUploadImageModel, handleImageSelect, isLoading, textMessage, onChange, value, onKeyUp, imageSrc, sx = {}, showEmojiPicker, onEmojiClick, toggleEmojiPicker, handleSendMessage } = props;
    const emojiPickerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                toggleEmojiPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [toggleEmojiPicker]);
    return selectedChat ? (
        <Fragment>
            <Box sx={{
                background: "#EFE9FF",
                position: "sticky",
                bottom: "0",
                zIndex: 1,
                boxSizing: "border-box",
                maxHeight: '300px',
                height: 'auto',

            }}>
                {replyMessage && (
                    <Box sx={{
                        background: "#D0BCFF",
                        padding: "8px",
                        marginBottom: "10px",
                        border: "1px solid #D0BCFF",
                        // overflowY: 'visible', // Allow the box to expand with content
                        borderRadius: '10px',
                        position: 'relative',
                        paddingRight: '32px',
                        maxHeight: '150px',
                        overflowY: 'auto'
                    }}>
                        {replyMessage?.msg &&
                            <Typography sx={{
                                color: "#000",
                                fontWeight: "500",
                                fontSize: "14px",
                                fontFamily: "Outfit",
                                lineClamp: 3,
                                WebkitLineClamp: 3,
                                textOverflow: "ellipsis",

                            }}>
                                Replying to: {replyMessage?.msg}
                            </Typography>
                        }

                        {replyMessage?.imageUrl &&
                            <img src={replyMessage?.imageUrl} alt='replyImage' style={{
                                maxWidth: '120px',
                                borderRadius: '8px',
                                height: '120px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                            }} />
                        }

                        <IconButton
                            size="small"
                            onClick={() => onCloseReply(null)}
                            sx={{
                                position: 'absolute',
                                top: '20px',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                color: '#000',
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                )}

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '10px 10px',
                    gridGap: '10px',
                    position: 'relative',
                }}>
                    <Box sx={{
                        width: '40px',
                        height: '40px',
                    }}>
                        <IconButton sx={{
                            background: '#EFE9FF',
                            border: "1px solid #EFE9FF",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                        }} onClick={() => setUploadImageModel(true)}>
                            <AttachFileIcon sx={{ fontSize: '28px', color: '#000000' }} />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '40px',
                    }}>
                        <IconButton onClick={toggleEmojiPicker} sx={{
                            width: '40px',
                            height: '40px',
                        }}>
                            <img src={EMOJIICON} alt='emoji' style={{
                                width: '100%',
                                height: '100%'
                            }} />
                        </IconButton>
                        {showEmojiPicker && (
                            <Box ref={emojiPickerRef}>
                                <CustomEmoji onEmojiClick={onEmojiClick} />
                            </Box>
                        )}
                    </Box>
                    <Box sx={{
                        width: 'calc(100% - 60px)'
                    }}>
                        <TextField
                            sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    outline: "none",
                                },
                                "& .MuiInputBase-root": {
                                    borderRadius: "20px",
                                    border: "1px solid #D0BCFF",
                                    color: "#000000",
                                    fontSize: "16px",
                                    background: '#fff',
                                    fontFamily: "Outfit",
                                    resize: "none",
                                    fontWeight: '500',
                                    paddingLeft: '10px',
                                },
                                ...sx,
                            }}
                            type={"text"}
                            placeholder={imageSrc ? "" : replyMessage ? `Replying to:` : "Type something"}
                            name={'text'}
                            onChange={onChange}
                            value={value}
                            onKeyDown={onKeyDown}
                            multiline
                            minRows={1}
                            maxRows={5}
                            disabled={imageSrc}
                            InputProps={{
                                startAdornment: imageSrc ? (
                                    <InputAdornment position="start">
                                        <img
                                            src={imageSrc}
                                            alt="preview"
                                            style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius: "10px" }}
                                        />
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                    </Box>
                    {(textMessage !== "" || imageSrc) && <Box sx={{
                        width: '52px',
                        height: '52px',
                    }}>
                        <IconButton onClick={handleSendMessage} sx={{
                            width: '52px',
                            height: '52px',
                            borderRadius: '50%',
                            background: '#14131E',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            "&:hover": {
                                background: '#14131E',
                            },
                            border: "1px solid #D0BCFF",
                        }}>
                            {isLoading ? <CircularProgress sx={{ width: '70px',height:'70px' }} /> : <SendIcon sx={{ color: '#fff' }} />}
                        </IconButton>
                    </Box>}
                </Box>
            </Box>
            <UserUploadImage
                open={uploadImageModel}
                onClose={() => {
                    setUploadImageModel(false);
                }}
                onImageSelect={handleImageSelect}

            />
        </Fragment>
    ) : (null);
}

export default UserSendTextField;
