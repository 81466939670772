import React from "react";
import { useDropzone } from "react-dropzone";
import { Box, IconButton, Typography } from "@mui/material";
import cloudIcon from "../../Assets/Images/Common/upload.svg";
import CloseIcon from "../../Assets/Images/Common/delete.svg";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

const CustomVideoUpload = ({ onDrop, accept, selectedFile, onRemove, isLoading, errors }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toast.error("Invalid file type for video!");
      }
    },
  });

  return (
    <Box
      sx={{
        textAlign: "center",
        p: isLoading || selectedFile ? "0px" : "0px",
        borderRadius: 4,
        border: errors ? "1.5px solid red" : "1px solid #BB9EFF",
        position: "relative",
        height: "125px",
      }}
    >
      {isLoading ? (
        <Skeleton height={120} borderRadius={20}/>
      ) : !selectedFile ? (
        <div
          {...getRootProps()}
          style={{
            cursor: "pointer",
            // padding: "20px",
            borderRadius: "8px",
            height: "125px",
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <img src={cloudIcon} alt="" />
            <Typography
              variant="body1"
              color="black"
              sx={{
                mt: 1,
                textAlign: "center",
                px: { xs: 2, sm: 3, md: 3, lg: 10, xl: 10 },
                fontWeight: "500",
              }}
            >
              Upload Video
            </Typography>
          </Box>
        </div>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <video
              controls
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                backgroundRepeat: 'none',
                backgroundPosition: 'center center',
                borderRadius: "10px",
              }}
            >
              <source src={selectedFile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
          <IconButton
            onClick={onRemove}
            sx={{
              position: "absolute",
              top: "0px",
              right: "0px",
              textTransform: "none",
            }}
          >
            <img src={CloseIcon} alt="delete" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CustomVideoUpload;
