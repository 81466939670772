import { Box, Dialog, Grid, IconButton, Radio, Typography, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import Constant from '../../../Utils/Constant';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SelectVideoListModel = (props) => {
    const { open, onClose, handleOnClose, handleSelectVideo } = props;
    const pageSize = 30;
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [videoList, setVideoList] = useState([]);
    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const [videoError, setVideoError] = useState(false);

    useEffect(() => {
        getVideoList();
    }, [page]);

    const getVideoList = async () => {
        setIsLoading(true);
        try {
            const response = await AxiosInstance.get(APILIST.GET_VIDEOS, {
                params: {
                    count: page * pageSize,
                    offset: pageSize,
                }
            });
            if (response && response?.data?.s) {
                const Rowdata = response?.data?.r?.map((data, i) => ({
                    rowid: i + 1 + page * pageSize,
                    ...data,
                }));
                setVideoList(Rowdata);
                setCount(response?.data?.c || 0);
            } else {
                setVideoList([]);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleVideoSelect = (event) => {
        setSelectedVideoId(event.target.value);
    };

    const handleSubmit = () => {
        if (selectedVideoId !== null) {
            handleSelectVideo(selectedVideoId)
            setVideoError(false);
            setSelectedVideoId(null);
        } else {
            setVideoError(true);
        }
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    padding: '20px 30px',
                    height: "auto",
                    overflow: "scroll",
                    "::-webkit-scrollbar": { width: "0px", height: '0px' }
                },
                "& .MuiDialogContent-root": {
                    overflowY: "initial",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '500', color: '#101010', fontFamily: "Outfit" }}> Video List</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}>
                        <IconButton sx={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#101010',
                            borderRadius: '8px',
                            "&:hover": {
                                backgroundColor: '#101010',
                                color: '#fff',
                            }
                        }} onClick={handleOnClose}>
                            <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ padding: '20px 0px 0px 0px' }}>
                {isLoading ? (
                    <Grid container spacing={2}>
                        {Array.from(new Array(3)).map((_, index) => (
                            <Grid item xs={12} key={index}>
                                <Skeleton height={150} borderRadius="10px" />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <RadioGroup value={selectedVideoId} onChange={handleVideoSelect}>
                        <Grid spacing={2} container>
                            {videoList && videoList.length > 0 && videoList.map((elem, index) => (
                                <Grid item xs={12} key={index}>
                                    <FormControlLabel
                                        value={elem?.id}
                                        control={<Radio />}
                                        sx={{
                                            width: '100%',
                                            '& .MuiFormControlLabel-label': {
                                                width: '100%',
                                            }
                                        }}
                                        label={
                                            <Box sx={{ height: '150px', width: '100%' }}>
                                                <video
                                                    controls
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        backgroundRepeat: 'none',
                                                        backgroundPosition: 'center center',
                                                        borderRadius: "10px",
                                                    }}
                                                >
                                                    <source src={`${Constant.BASE_URL}${elem?.video}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </Box>
                                        }
                                    />
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                {videoError && <Typography
                                    sx={{
                                        color: "red",
                                        fontWeight: "400",
                                        fontSize: { xs: "14px", sm: "14px" },
                                        marginTop: "5px",
                                        paddingLeft: '10px'
                                    }}
                                >
                                    Please select a video.
                                </Typography>}
                            </Grid>
                            <Grid item xs={12}>
                                <CustomSubmitButton
                                    btnTitle={"Add"}
                                    loading={isLoading}
                                    type="button"
                                    onSubmit={handleSubmit}
                                />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                )}
            </Box>
        </Dialog>
    );
};

export default SelectVideoListModel;
