import React, { useCallback, useState } from "react";
import { Box, Dialog } from "@mui/material";
import { toast } from "react-toastify";
import CustomThumbUpload from "../../../Components/CustomFileUpload/UploadImage";
import CustomSubmitButton from "../../../Components/CustomSubmitButton/CustomSubmitButton";

const UserUploadImage = (props) => {
  const [selectedThumb, setSelectedThumb] = useState(null);
  const [selectedThumbFile, setSelectedThumbFile] = useState(null);

  const handleOnChangeThumb = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toast.error("Invalid file type for thumb!");
      } else {
        const file = acceptedFiles[0];
        if (file && ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(file.type)) {
          if (file.size <= 2 * 1024 * 1024) {
            const videoUrl = URL.createObjectURL(file);
            setSelectedThumbFile(file);
            setSelectedThumb(videoUrl);
            props.onImageSelect({ file, videoUrl });
          } else {
            toast.error(`File size exceeds the limit of 2MB`);
          }
        } else {
          toast.error("Upload Only (PNG, JPG, JPEG)");
        }
      }
    },
    [props]
  );

  const handleRemoveImage = () => {
    setSelectedThumb(null);
    setSelectedThumbFile(null);
    props.onImageSelect(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onClose();
    setSelectedThumb(null);
    setSelectedThumbFile(null);

  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "20px",
          padding: "20px 30px",
          height: "240px",
        //   overflow: "scroll",
        },
        // "& .MuiDialogContent-root": {
        //   overflowY: "initial",
        // },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
    >
      <CustomThumbUpload
        accept="image/*"
        onDrop={handleOnChangeThumb}
        selectedFile={selectedThumb}
        onRemove={handleRemoveImage}
      />
      <Box sx={{ padding: "20px 0px 0px 0px" }}>
        <CustomSubmitButton btnTitle={"Upload Image"} type="button" onSubmit={handleSubmit} />
      </Box>
    </Dialog>
  );
};

export default UserUploadImage;
