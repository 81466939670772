import { Box, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment';
import Constant from '../../../Utils/Constant';
const UserProfileTop = ({ selectedChat }) => {
    const timestamp = new Date((selectedChat?.date?.seconds * 1000) + (selectedChat?.date?.nanoseconds / 1000000));
    const getInitials = (name) => {
        if (!name) return '';
        const parts = name.split(' ');
        const firstTwoParts = parts.slice(0, 2);
        const initials = firstTwoParts.map(part => part.charAt(0).toUpperCase()).join('');
      
        return initials;
      };
    return selectedChat ? (
        <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gridGap: '10px',
            borderBottom: '1px solid #00000033',
            padding: '10px 20px 10px 20px',
            position:'fixed',
            zIndex: 999,
            top:'0',
            backgroundColor:'#EFE9FF'
        }}>
            <Box sx={{
                width: '60px',
                height: '60px',
                borderRadius: '50%'
            }}>
                {selectedChat?.userProfileData?.imageUrl ? (<img src={`${Constant.BASE_URL}${selectedChat?.userProfileData?.imageUrl}`} alt='userProfile' style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    objectFit: 'cover'
                }} />) : (<Typography sx={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    backgroundColor: '#ddd',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '24px',
                    color: '#000000',
                    fontFamily: "Outfit",
                    fontWeight: '600',
                    textTransform: 'capitalize'
                }}>{getInitials(selectedChat?.userProfileData?.user_name)}</Typography>)}
            </Box>
            <Box sx={{}}>
                <Typography sx={{
                    color: '#000000',
                    fontWeight: '400',
                    fontSize: '20px',
                    fontFamily: 'Outfit',
                    textTransform: 'capitalize'
                }}>{selectedChat?.userProfileData?.user_name}</Typography>
                <Typography sx={{
                    fontSize: '16px',
                    fontFamily: "Outfit",
                    color: '#000000B2',
                    fontWeight: '400',
                }}>
                    {moment(timestamp).format('D MMM')}
                </Typography>
            </Box>
        </Box>
    ) : (
        <Box sx={{ textAlign: 'center', padding: '20px' }}>
            <Typography variant="body1">No chat selected. Please select a chat to start messaging.</Typography>
        </Box>
    )
}

export default UserProfileTop