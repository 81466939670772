import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import { useFormik } from "formik";
import EditIcon from "../../../Assets/Images/Common/EditIcon.png";
import Skeleton from "react-loading-skeleton";
import AxiosInstance from "../../../Config/AxiosInstance";
import { toast } from "react-toastify";
import CustomSubmitButton from "../../../Components/CustomSubmitButton/CustomSubmitButton";
import APILIST from "../../../Config/ApiList";

const TermsCondition = () => {
  const refTerms = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const getTermsCondition = async () => {
    try {
      const response = await AxiosInstance.get(APILIST.GET_TERMS_CONDITION);
      if (response && response?.data?.s === 1) {
        formik.setFieldValue("termsCondition", response?.data?.r?.content
        );
        setIsLoading(false);
        setEditMode(true);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to fetch privacy policy. Please try again.");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTermsCondition();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      termsCondition: "",
    },
    validationSchema: Yup.object({
      termsCondition: Yup.string().required("Terms condition is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        try {
          const formData = new FormData();
          formData.append("content", values.termsCondition);
          const res = await AxiosInstance.post(
            APILIST.ADD_UPDATE_TERMS_CONDITION,
            formData
          );
          if (res && res?.data?.s) {
            toast.success(res.data.m);
            resetForm();
            getTermsCondition()
          }
        } catch (error) {
          console.log('error', error);
          toast.error(error);
        } finally{
          setIsLoading(false);
        }
      }
    }
  });
  return (
    <Box
        sx={{
          width: "100%",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
          style={{
            position: "relative",
            backgroundColor: "#fff",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            padding: "40px 10px 10px 10px",
            borderRadius: "12px",
          }}
        >
          {isEditMode && (
            <Tooltip title={'Edit'}>
              <IconButton
              type="button"
              sx={{
                position: "absolute",
                top: "5px",
                right: "10px",
                width: "30px",
                height: "30px",
                backgroundColor: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
                cursor: "pointer",
              }}
              onClick={() => setEditMode(false)}
            >
              <img src={EditIcon} alt="editIcon" />
            </IconButton>
              </Tooltip>
              
          )}
          {isLoading ? (
            <Skeleton width={"100%"} height={"300px"} />
          ) : (
            <ReactQuill
              placeholder="Description"
              value={formik.values.termsCondition}
              ref={refTerms}
              readOnly={isEditMode}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [
                    { align: "right" },
                    { align: "center" },
                    { align: null },
                    { align: "justify" },
                  ],
                ],
              }}
              onChange={(content, delta, source, editor) => {
                content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
                formik.setFieldValue("termsCondition", content);
              }}
              className="termsCondtionContent"
            />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <CustomSubmitButton
              type="submit"
              variant="contained"
              fullWidth
              disabled={
                !formik?.values?.termsCondition
                  ?.replace(/<[^>]+>/g, "")
                  .trim() || isEditMode
              }
              btnTitle="Save"
              loading={isLoading}
              sx={{
                width: "120px",
                height: "48px",
              }}
            />
          </Box>
        </form>
      </Box>
  )
}

export default TermsCondition
