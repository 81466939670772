import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import CustomDropDown from '../../../Components/CustomDropDown/CustomDropDown';
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import CustomThumbUpload from '../../../Components/CustomFileUpload/UploadImage';
import CustomVideoUpload from '../../../Components/CustomFileUpload/VideoUpload';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import Constant from '../../../Utils/Constant';
const AddVideoModel = (props) => {
    const { open, onClose, typeCheck, editVideoId, handleOnSubmit, handleOnClose } = props;
    const [categoryList, setCategoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedThumb, setSelectedThumb] = useState(null);
    const [selectedThumbFile, setSelectedThumbFile] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedVideoFile, setSelectedVideoFile] = useState(null);
    const handleOnChangeThumb = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error("Invalid file type for thumb!");
        } else {
            const file = acceptedFiles[0];
            if (file && ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(file.type)) {
                if (file.size <= 2 * 1024 * 1024) {
                    const videoUrl = URL.createObjectURL(file);
                    setSelectedThumbFile(file);
                    setSelectedThumb(videoUrl);
                    formik.setFieldValue('thumb', file);
                } else {
                    toast.error(`File size exceeds the limit of 2MB`);
                }
            } else {
                toast.error("Upload Only(PNG.JPG,JPEG,WEBP)");
            }
        }
    }, []);
    const handleOnChangeVideo = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error("Invalid file type for video!");
        } else {
            const file = acceptedFiles[0];
            if (file) {
                // Check if the file type is a valid video format
                if (["video/mp4", "video/quicktime", "video/x-m4v"].includes(file.type)) {
                    if (file.size <= 50 * 1024 * 1024) { // Increased file size limit to 50MB for video
                        const videoUrl = URL.createObjectURL(file);
                        setSelectedVideoFile(file);
                        setSelectedVideo(videoUrl);
                        formik.setFieldValue('video', file);
                    } else {
                        toast.error("File size exceeds the limit of 50MB");
                    }
                } else {
                    toast.error("Upload Only MP4 or other supported video formats");
                }
            }
        }
    }, []);
    useEffect(() => {
        if (typeCheck === 'EDIT' && editVideoId) {
          getVideoDetails(editVideoId);
        } else {
          formik.resetForm();
          setSelectedThumb(null);
          setSelectedThumbFile(null);
          setSelectedVideo(null);
          setSelectedVideoFile(null);
        }
      }, [typeCheck, editVideoId]);
      
    const getVideoDetails = async (VID) => {
        setIsLoading(true);
        try {
            const response = await AxiosInstance.get(APILIST.GET_VIDEO_DETAILS, {
                params: {
                    id: VID
                }
            });
            if (response && response?.data?.s) {
                const editVideoResponse = response?.data?.r;
                const categoryMatch = categoryList?.find((elem) => elem?.category_name === editVideoResponse?.category_name)
                formik.setValues({
                    selectCategory: categoryMatch?.id || 0,
                    title: editVideoResponse?.title || "",
                    description: editVideoResponse?.description || "",
                    thumb: editVideoResponse?.thumb || null,
                    video: editVideoResponse?.video || null
                })
                if (editVideoResponse && editVideoResponse?.thumb) {
                    setSelectedThumb(`${Constant.BASE_URL}${editVideoResponse?.thumb}`);
                }
                if (editVideoResponse && editVideoResponse?.video) {
                    setSelectedVideo(`${Constant.BASE_URL}${editVideoResponse?.video}`);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.log('error', error);
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getCategoryList();
    }, [])
    const getCategoryList = async () => {
        try {
            const response = await AxiosInstance.get(APILIST.GET_CATEGORY);
            if (response && response?.data?.s) {
                setCategoryList(response?.data?.r)
            }
        } catch (error) {
            console.log('error', error);
        }
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            selectCategory: 0,
            title: "",
            description: "",
            thumb: null,
            video: null
        },
        validationSchema: Yup.object().shape({
            selectCategory: Yup.mixed()
                .test(
                    "is-not-zero",
                    "Category name is required!",
                    (value) => value !== 0
                )
                .required("SelectCategory is required!"),
            title: Yup.string().required("Title is required"),
            description: Yup.string().required("Description is required"),
            thumb: Yup.string().required("Thumb is required"),
            video: Yup.string().required("Video is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            if (isLoading) {
                return;
            } else {
                try {
                    const formData = new FormData();
                    if (editVideoId) {
                        formData.append('id', editVideoId);
                    }
                    formData.append('category_id', values.selectCategory);
                    formData.append('title', values.title);
                    formData.append('description', values.description);
                    if (selectedThumbFile) {
                        formData.append('thumb', values.thumb);
                    }
                    if (selectedVideoFile) {
                        formData.append('video', values.video);
                    }
                    const apiEndpoint = editVideoId ? APILIST.EDIT_VIDEO : APILIST.ADD_VIDEO;
                    const response = await AxiosInstance.post(apiEndpoint, formData);

                    if (response && response.data?.s) {
                        toast.success(editVideoId ? 'Video Updated Successfully!' : 'Video Added Successfully!');
                        resetForm();
                        setSelectedThumb(null);
                        setSelectedThumbFile(null);
                        setSelectedVideo(null);
                        setSelectedVideoFile(null);
                        handleOnSubmit()
                    } else {
                        toast.error('An unexpected error occurred');
                    }
                } catch (error) {
                    toast.error(error.message || 'An error occurred');
                } finally {
                    setIsLoading(false);
                }
            }
        },
    });
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    padding: '20px 30px',
                    height: "600px",
                    overflow: "scroll",
                    "::-webkit-scrollbar": { width: "0px", height: '0px' }
                },
                "& .MuiDialogContent-root": {
                    overflowY: "initial",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }
            }}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', color: '#101010', fontFamily: "Outfit" }}>{typeCheck ? "Update" : "Add"} Video</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <IconButton sx={{
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#101010',
                                borderRadius: '8px',
                                "&:hover": {
                                    backgroundColor: '#101010',
                                    color: '#fff',
                                }
                            }} onClick={handleOnClose}>
                                <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    padding: '20px 0px 0px 0px',
                }}>
                    <Grid spacing={2} container>
                        <Grid item xs={12}>
                            <CustomDropDown
                                Title={"Select Category"}
                                value={formik.values.selectCategory}
                                onChange={formik.handleChange}
                                placeholder={'Select Category'}
                                menuList={categoryList}
                                NoData='No Category Available'
                                isLoading={isLoading}
                                name={'selectCategory'}
                                errors={formik.touched.selectCategory && formik.errors.selectCategory}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Title"
                                type="text"
                                placeholder="Title"
                                name="title"
                                value={formik.values.title}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.title && formik.errors.title}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.title ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Description"
                                type="text"
                                placeholder="Description"
                                name="description"
                                value={formik.values.description}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.description && formik.errors.description}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: formik.errors.description ? "1px solid red" : "1px solid #BB9EFF",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                multiline={true}
                                rows={3}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                    fontSize: { xs: "16px", sm: "18px" },
                                    mb: '8px'
                                }}
                            >
                                Thumbnail
                            </Typography>
                            <CustomThumbUpload
                                accept="image/*"
                                onDrop={handleOnChangeThumb}
                                selectedFile={selectedThumb}
                                onRemove={(e) => {
                                    e.stopPropagation();
                                    setSelectedThumb(null);
                                    setSelectedThumbFile(null);
                                    formik.setFieldValue('thumb', null)
                                }}
                                typeCheck={typeCheck}
                                isLoading={isLoading}
                                errors={formik.errors.thumb}
                            />
                            {formik.touched.thumb && formik.errors.thumb ? (
                                <Typography
                                    sx={{
                                        color: "red",
                                        fontWeight: "400",
                                        fontSize: { xs: "14px", sm: "14px" },
                                        marginTop: "5px",
                                    }}
                                >
                                    {formik.errors.thumb}
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                    fontSize: { xs: "16px", sm: "18px" },
                                    mb: '8px'
                                }}
                            >
                                Upload Video
                            </Typography>
                            <CustomVideoUpload
                                accept="video/*"
                                onDrop={handleOnChangeVideo}
                                selectedFile={selectedVideo}
                                onRemove={(e) => {
                                    e.stopPropagation();
                                    setSelectedVideo(null);
                                    setSelectedVideoFile(null);
                                    formik.setFieldValue('video', null)
                                }}
                                typeCheck={typeCheck}
                                isLoading={isLoading}
                                errors={formik.errors.video}
                            />
                            {formik.touched.video && formik.errors.video ? (
                                <Typography
                                    sx={{
                                        color: "red",
                                        fontWeight: "400",
                                        fontSize: { xs: "14px", sm: "14px" },
                                        marginTop: "5px",
                                    }}
                                >
                                    {formik.errors.video}
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid item xs={12}>
                            <CustomSubmitButton
                                btnTitle={typeCheck ? "Update Video" : "Add Video"}
                                loading={isLoading}
                                type="submit"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Dialog>
    );
};

export default AddVideoModel;