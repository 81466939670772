import { Box, Dialog, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Constant from '../../../Utils/Constant';
import "react-loading-skeleton/dist/skeleton.css";
import CloseIcon from '@mui/icons-material/Close';
const ShowFullScreenVideo = (props) => {
    const { open, onClose, openFullScreenVideoModelData, handleOnClose } = props;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{
                
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    padding: '20px 30px',
                    height: "auto",
                    overflow: "scroll",
                    backgroundColor:'transparent',
                    "::-webkit-scrollbar": { width: "0px", height: '0px' }
                },
                "& .MuiDialogContent-root": {
                    overflowY: "initial",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: 'rgba(0, 0, 0, 0.9)',
                }
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}>
                        <IconButton sx={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#101010',
                            borderRadius: '8px',
                            "&:hover": {
                                backgroundColor: '#101010',
                                color: '#fff',
                            }
                        }} onClick={handleOnClose}>
                            <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ padding: '20px 0px 0px 0px',
                height:'410px',
                width:'100%'
             }}>
                <video
                    controls
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        backgroundRepeat: 'none',
                        backgroundPosition: 'center center',
                        borderRadius: "10px",
                    }}
                >
                    <source src={`${Constant.BASE_URL}${openFullScreenVideoModelData?.video}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Box>
        </Dialog>
    );
};

export default ShowFullScreenVideo;
