import { Box, Typography, Grid, IconButton } from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import CustomThumbUpload from '../../../Components/CustomFileUpload/UploadImage';
import { toast } from 'react-toastify';
import CustomDropDown from '../../../Components/CustomDropDown/CustomDropDown';
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import { useLocation, useNavigate } from 'react-router-dom';
import PlanWorkOutList from './PlanWorkOutList';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import Constant from '../../../Utils/Constant';
import Swal from 'sweetalert2';
const AddRunnerPlan = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [typeCheck, setTypeCheck] = useState(null);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [selectedBannerFile, setSelectedBannerFile] = useState(null);
    const [planTypeList, setPlanTypeList] = useState([]);
    const [isEditRunnerPlanID, setIsEditRunnerPlanID] = useState(null);
    const [isSelectedDay, setIsSelectedDay] = useState(0);
    const [isSelectedDays, setIsSelectedDays] = useState(null);
    const [isSelectedRunnerPlanDetails, setIsSelectedRunnerPlanDetails] = useState([]);
    const [openAddWorkOutModel, setOpenAddWorkOutModel] = useState(false);
    const [isDisabledSelectDays, setIsDisabledSelectedDays] = useState(false);
    const planLevelList = [
        {
            id: '1',
            name: "beginner",
            label: "beginner"
        },
        {
            id: '2',
            name: "intermediate",
            label: "intermediate"
        },
        {
            id: '3',
            name: "advanced",
            label: "advanced"
        },
    ]
    const totalDayPlanList = [
        { "id": "1", "name": "1", "value": "1" },
        { "id": "2", "name": "2", "value": "2" },
        { "id": "3", "name": "3", "value": "3" },
        { "id": "4", "name": "4", "value": "4" },
        { "id": "5", "name": "5", "value": "5" },
        { "id": "6", "name": "6", "value": "6" },
        { "id": "7", "name": "7", "value": "7" },
    ]
    const handleOnChangeThumb = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error("Invalid file type for thumb!");
        } else {
            const file = acceptedFiles[0];
            if (file && ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(file.type)) {
                if (file.size <= 2 * 1024 * 1024) {
                    const bannerUrl = URL.createObjectURL(file);
                    setSelectedBannerFile(file);
                    setSelectedBanner(bannerUrl);
                    AddPlanValidation.setFieldValue('banner', file, false);
                } else {
                    toast.error(`File size exceeds the limit of 2MB`);
                }
            } else {
                toast.error("Upload Only(PNG.JPG,JPEG)");
            }
        }
    }, []);
    useEffect(() => {
        const getPlanType = async () => {
            setIsLoading(true)
            try {
                const response = await AxiosInstance.get(APILIST.GET_PLAN_TYPE);
                if (response && response?.data?.s) {
                    setPlanTypeList(response?.data?.r);
                    setIsLoading(false)
                } else {
                    setPlanTypeList([])
                    setIsLoading(false)
                }
            } catch (error) {
                console.log('error', error);
                setIsLoading(false)
            }
        }
        getPlanType();
    }, [])
    const handleSelectDaysOnChange = (e) => {
        const selectDay = parseInt(e.target.value);
        const selectedDays = [];

        for (let i = 1; i <= selectDay; i++) {
            selectedDays.push({ [i]: Array.from({ length: i }, (_, j) => j + 1) });
        }

        if (selectDay) {
            window.sessionStorage.setItem('planDay', JSON.stringify(selectedDays));
            window.sessionStorage.setItem('planDayNumber', selectDay);
            setIsSelectedDay(selectDay);
            setIsSelectedDays(selectedDays);
        }
    };
    const handleWorkOutOnSubmit = async () => {
        setIsLoading(true);
        try {
            setOpenAddWorkOutModel(false);
            const runnerPID = window.sessionStorage.getItem('PlanID');

            if (runnerPID) {
                await getByIdRunnerPlanDetailsOne(runnerPID);
            }
        } catch (error) {
            console.error("An error occurred while submitting the workout:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleDeletePlanDetailsUpdate = async () => {
        setIsLoading(true);
        try {
            setOpenAddWorkOutModel(false);
            const runnerPID = window.sessionStorage.getItem('PlanID');

            if (runnerPID) {
                await getByIdRunnerPlanDetailsOne(runnerPID);
            }
        } catch (error) {
            console.error("An error occurred while submitting the workout:", error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        const runnerPID = window.sessionStorage.getItem('PlanID');
        if (runnerPID) {
            getByIdRunnerPlanDetailsOne(runnerPID);
        }
    }, []) 
    useEffect(() => {
        if (location?.state?.runnerPlanId) {
            const runnerPID = location?.state?.runnerPlanId;
            if (runnerPID) {
                getByIdRunnerPlanDetailsOne(runnerPID);
            }
        }
    }, [location?.state?.runnerPlanId])
    const getByIdRunnerPlanDetailsOne = async (runnerPID) => {
        setIsLoading(true);
        try {
            const response = await AxiosInstance.get(APILIST.GET_PLAN_DETAILS, {
                params: {
                    id: runnerPID,
                    is_admin: 1
                }
            });

            if (response && response?.data?.s) {
                const resData = response?.data?.r;
                const planDetails = resData?.plan_details || [];

                // Calculate day entry count
                // const dayEntryCount = planDetails.reduce((acc, { day }) => {
                //     acc[day] = (acc[day] || 0) + 1;
                //     return acc;
                // }, {});
                // const hasError = Object.keys(dayEntryCount).some(day => {
                //     const requiredEntries = parseInt(day, 7);
                //     return dayEntryCount[day] !== requiredEntries;
                // });
                const dayEntryMap = planDetails.reduce((acc, { day, position }) => {
                    if (!acc[day]) acc[day] = [];
                    acc[day].push(position);
                    return acc;
                }, {});
                
                const hasError = Object.keys(dayEntryMap).some(day => {
                    const positions = dayEntryMap[day];
                    const requiredPositions = Array.from({ length: day }, (_, index) => index + 1); // Generates required positions [1, 2, ..., day]
                    return !requiredPositions.every(pos => positions.includes(pos));
                });                
                setIsDisabledSelectedDays(hasError);
                AddPlanValidation.setValues({
                    banner: resData?.cover_img,
                    selectPlanType: resData?.cat_id,
                    planTitle: resData?.title,
                    planLevel: resData?.level,
                    planDuration: resData?.weeks,
                    planDescription: resData?.description
                });

                if (resData?.cover_img) {
                    setSelectedBanner(`${Constant.BASE_URL}${resData?.cover_img}`);
                }

                if (resData?.id) {
                    setIsEditRunnerPlanID(resData?.id);
                    setIsSelectedRunnerPlanDetails(resData?.plan_details);
                } else {
                    setIsEditRunnerPlanID(null);
                }
                const selectDay = parseInt(resData?.plan_details[resData?.plan_details?.length - 1]?.day, 10);
                const selectedDays = [];

                for (let i = 1; i <= selectDay; i++) {
                    selectedDays.push({ [i]: Array.from({ length: i }, (_, j) => j + 1) });
                }
                if (selectDay) {
                    window.sessionStorage.setItem('planDay', JSON.stringify(selectedDays));
                    window.sessionStorage.setItem('planDayNumber', selectDay);
                    setIsSelectedDay(selectDay);
                    setIsSelectedDays(selectedDays);
                }
                if (location?.state?.runnerPlanId) {
                    window.sessionStorage.setItem('PlanID', location?.state?.runnerPlanId);
                    const selectDay = parseInt(resData?.plan_details[resData?.plan_details?.length - 1]?.day, 10);
                    const selectedDays = [];

                    for (let i = 1; i <= selectDay; i++) {
                        selectedDays.push({ [i]: Array.from({ length: i }, (_, j) => j + 1) });
                    }
                    if (selectDay) {
                        window.sessionStorage.setItem('planDay', JSON.stringify(selectedDays));
                        window.sessionStorage.setItem('planDayNumber', selectDay);
                        setIsSelectedDay(selectDay);
                        setIsSelectedDays(selectedDays);
                    }
                }
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    }
    const handleOnClickPublish = async () => {
        if (isEditRunnerPlanID) {
            setIsLoading(true);
            try {
                const { data } = await AxiosInstance.get(APILIST.GET_PLAN_DETAILS, {
                    params: { id: isEditRunnerPlanID },
                });
                if (data?.s) {
                    const planDetails = data.r?.plan_details || [];
                    const dayEntryMap = planDetails.reduce((acc, { day, position }) => {
                        if (!acc[day]) acc[day] = [];
                        acc[day].push(position);
                        return acc;
                    }, {});
                    const hasError = Object.keys(dayEntryMap).some(day => {
                        const positions = dayEntryMap[day];
                        const requiredPositions = Array.from({ length: day }, (_, index) => index + 1); // Generates required positions [1, 2, ..., day]
                        return !requiredPositions.every(pos => positions.includes(pos));
                    });
    
                    if (hasError) {
                        toast.error('You have incomplete data in the workout plan. Please complete it before publishing.');
                        return;
                    }
                    const updateResponse = await AxiosInstance.post(APILIST.UPDATE_DELETE_RUNNER_PLAN, {
                        id: isEditRunnerPlanID,
                        status: 1,
                    });
                    if (updateResponse?.data?.s) {
                        window.sessionStorage.clear();
                        toast.success('Data has been successfully published.');
                        navigate('/admin/runner-plan');
                    } else {
                        console.error('Failed to update the runner plan');
                    }
    
                } else {
                    console.error('Failed to fetch plan details');
                }
            } catch (error) {
                console.error('Error in handleOnClickPublish:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            toast.error("Please create and publish your plan before proceeding.");
        }
    };
    const handleBackNavigate = async () => {
        const expectedPositions = {
            1: [1],
            2: [1, 2],
            3: [1, 2, 3],
            4: [1, 2, 3, 4],
            5: [1, 2, 3, 4, 5],
            6: [1, 2, 3, 4, 5, 6],
            7: [1, 2, 3, 4, 5, 6, 7],
        };
    
        if (isEditRunnerPlanID) {
            if (isSelectedRunnerPlanDetails?.length > 0) {
                const planDetails = isSelectedRunnerPlanDetails || [];
                const dayPositionMap = planDetails.reduce((acc, { day, position }) => {
                    if (!acc[day]) acc[day] = new Set();
                    acc[day].add(position);
                    return acc;
                }, {});
    
                const mismatchedDays = Object.keys(dayPositionMap).filter(day => {
                    const positions = Array.from(dayPositionMap[day]).sort();
                    const expected = expectedPositions[day] || [];
                    return JSON.stringify(positions) !== JSON.stringify(expected);
                });
    
                if (mismatchedDays.length > 0) {
                    Swal.fire({
                        title: "You have unpublished changes, do you want to continue?",
                        text: "If you choose yes, your unpublished data will be deleted.",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#46adf2",
                        cancelButtonColor: "#5b5d5d",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            const misMatches = planDetails.filter((elem) => mismatchedDays.includes(String(elem.day)));
                            const selectedIDs = misMatches.map(detail => detail.id).join(',');
                            try {
                                const params = { remove_ids: selectedIDs };
                                console.log('params', params);
                                
                                const response = await AxiosInstance.post(APILIST.UPDATE_DELETE_PLAN_DETAILS, params);
    
                                if (response.data?.s) {
                                    Swal.fire({
                                        title: "Unpublished",
                                        text: "Unpublished changes have been deleted. Redirecting to runner plan page...",
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    }).then(() => {
                                        window.sessionStorage.clear();
                                        navigate('/admin/runner-plan');
                                    });
                                } else {
                                    toast.error("Failed to delete the unpublished changes. Please try again.");
                                }
                            } catch (error) {
                                toast.error("An error occurred while deleting the unpublished changes.");
                            }
                        }
                    });
                } else {
                    window.sessionStorage.clear();
                    navigate('/admin/runner-plan');
                }
            } else {
                navigate('/admin/runner-plan');
                window.sessionStorage.clear();
            }
        } else {
            navigate('/admin/runner-plan');
            window.sessionStorage.clear();
        }
    };
    
    
    const handleDeletePlandetailsDayAllPosition = async (isSelectedDay) => {
        Swal.fire({
            title: "Are you sure you want to delete all workouts for the selected day?",
            text: "If you choose yes, all workout data for the selected day will be deleted.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#46adf2",
            cancelButtonColor: "#5b5d5d",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const planDetails = isSelectedRunnerPlanDetails || [];
                const matchedEntries = planDetails.filter(detail => detail.day === isSelectedDay);
                if (matchedEntries.length > 0) {
                    const selectedIDs = matchedEntries.map(detail => detail.id).join(',');
                    try {
                        const params = { remove_ids: selectedIDs };
                        console.log('params', params);
                        const response = await AxiosInstance.post(APILIST.UPDATE_DELETE_PLAN_DETAILS, params);
                        if (response.data?.s) {
                            Swal.fire({
                                title: "Deleted",
                                text: "All workouts for the selected day have been deleted. Redirecting to the runner plan page...",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500,
                            }).then(() => {
                                const runnerPID = window.sessionStorage.getItem('PlanID');
                                if (runnerPID) {
                                    getByIdRunnerPlanDetailsOne(runnerPID);
                                }
                            });
                        } else {
                            toast.error("Failed to delete the selected day workouts. Please try again.");
                        }
                    } catch (error) {
                        toast.error("An error occurred while deleting the workouts.");
                    }
                } else {
                    toast.info("No workouts found for the selected day.");
                }
            }
        });
    };
    
    const AddPlanValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            banner: null,
            selectPlanType: 0,
            planTitle: "",
            planLevel: 0,
            planDuration: "",
            planDescription: ""
        },
        validationSchema: Yup.object().shape({
            banner: Yup.mixed().required("Banner is required"),
            selectPlanType: Yup.mixed()
                .test("is-not-zero", "Plan type is required!", value => value !== 0)
                .required("Plan type is required!"),
            planTitle: Yup.string().required("Plan title is required"),
            planLevel: Yup.mixed()
                .test("is-not-zero", "Plan level is required!", value => value !== 0)
                .required("Plan level is required!"),
            planDuration: Yup.string().required("Plan duration is required"),
            planDescription: Yup.string().required("Plan description is required"),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            if (isLoading) {
                return;
            } else {
                if (isEditRunnerPlanID) {
                    try {
                        const formData = new FormData();
                        formData.append('id', isEditRunnerPlanID);
                        formData.append('cat_id', values.selectPlanType);
                        formData.append('level', values.planLevel);
                        formData.append('weeks', values.planDuration);
                        formData.append('title', values.planTitle);
                        formData.append('description', values.planDescription);
                        if (selectedBannerFile) {
                            formData.append('cover_img', selectedBannerFile);
                        }
                        const response = await AxiosInstance.post(APILIST.UPDATE_DELETE_RUNNER_PLAN, formData);
                        if (response && response.data?.s) {
                            const runnerPID = window.sessionStorage.getItem('PlanID');
                            toast.success("Plan updated successfully!");
                            resetForm();
                            setSelectedBanner(null);
                            setSelectedBannerFile(null);
                            if (runnerPID) {
                                getByIdRunnerPlanDetailsOne(runnerPID);
                                window.sessionStorage.setItem('PlanID', runnerPID);
                            }
                        } else {
                            toast.error("Failed to updated the plan. Please try again.");
                        }
                    } catch (error) {
                        toast.error(error.message || "An error occurred while updated the plan.");
                    } finally {
                        setIsLoading(false);
                    }
                } else {
                    try {
                        const formData = new FormData();
                        formData.append('cat_id', values.selectPlanType);
                        formData.append('level', values.planLevel);
                        formData.append('weeks', values.planDuration);
                        formData.append('title', values.planTitle);
                        formData.append('description', values.planDescription);
                        formData.append('cover_img', selectedBannerFile);
                        const response = await AxiosInstance.post(APILIST.ADD_PLAN, formData);
                        if (response && response.data?.s) {
                            const runnerPID = response?.data?.r?.id;
                            toast.success("Plan added successfully!");
                            resetForm();
                            setSelectedBanner(null);
                            setSelectedBannerFile(null);
                            if (runnerPID) {
                                getByIdRunnerPlanDetailsOne(runnerPID);
                                window.sessionStorage.setItem('PlanID', runnerPID);
                            }
                        } else {
                            toast.error("Failed to add the plan. Please try again.");
                        }
                    } catch (error) {
                        toast.error(error.message || "An error occurred while adding the plan.");
                    } finally {
                        setIsLoading(false);
                    }
                }
            }
        },
    });
    return (
        <Fragment>
            <Box sx={{
                width: '100%',
                padding: '15px'
            }}>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'fixed',
                    top: '0px',
                    left: '0px',
                    backgroundColor: '#fff',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
                    padding: '10px',
                    zIndex: '2'
                }}>
                    <IconButton sx={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#101010',
                        borderRadius: '8px',
                        "&:hover": {
                            backgroundColor: '#101010',
                            color: '#fff',
                        }
                    }} onClick={() => handleBackNavigate()}>
                        <ArrowBackIosNewIcon sx={{ color: '#fff', fontSize: '28px' }} />
                    </IconButton>
                    <CustomSubmitButton
                        btnTitle={"Publish"}
                        type="submit"
                        sx={{
                            width: '130px'

                        }}
                        onSubmit={() => handleOnClickPublish()}
                        loading={isLoading}
                    />
                </Box>
                <Box sx={{
                    width: '100%',
                    padding: '70px 0px 30px 0px'
                }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={5}>
                            <Box sx={{
                                background: '#EFE9FF80',
                                padding: { xs: "15px", lg: "30px" },
                                borderRadius: '8px'
                            }}>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    AddPlanValidation.handleSubmit();
                                    return false;
                                }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    color: "black",
                                                    fontWeight: "400",
                                                    textAlign: "left",
                                                    fontSize: { xs: "16px", sm: "18px" },
                                                    mb: '8px'
                                                }}
                                            >
                                                Banner
                                            </Typography>
                                            <CustomThumbUpload
                                                accept="image/*"
                                                onDrop={handleOnChangeThumb}
                                                selectedFile={selectedBanner}
                                                onRemove={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedBanner(null);
                                                    setSelectedBannerFile(null);
                                                    AddPlanValidation.setFieldValue('banner', null)
                                                }}
                                                typeCheck={typeCheck}
                                                isLoading={isLoading}
                                                errors={AddPlanValidation.errors.banner}
                                            />
                                            {AddPlanValidation.touched.banner && AddPlanValidation.errors.banner ? (
                                                <Typography
                                                    sx={{
                                                        color: "red",
                                                        fontWeight: "400",
                                                        fontSize: { xs: "14px", sm: "14px" },
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    {AddPlanValidation.errors.banner}
                                                </Typography>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomDropDown
                                                name='selectPlanType'
                                                Title={"Select Plan Type"}
                                                value={AddPlanValidation.values.selectPlanType}
                                                onChange={AddPlanValidation.handleChange}
                                                placeholder={'Select Plan Type'}
                                                menuList={planTypeList}
                                                NoData='No Plan Type Available'
                                                isLoading={isLoading}
                                                getLabel={(item) => item}
                                                errors={AddPlanValidation.touched.selectPlanType && AddPlanValidation.errors.selectPlanType}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                label="Plan Title"
                                                type="text"
                                                placeholder="Plan Title"
                                                name="planTitle"
                                                value={AddPlanValidation.values.planTitle}
                                                errors={AddPlanValidation.touched.planTitle && AddPlanValidation.errors.planTitle}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    if (value.trimStart() !== value) {
                                                        e.target.value = value.trimStart();
                                                    }
                                                    AddPlanValidation.handleChange(e);
                                                }}
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        borderRadius: "10px",
                                                        border: AddPlanValidation.errors.planTitle ? "1px solid red" : "1px solid #BB9EFF",
                                                        color: "black",
                                                        fontSize: "18px",
                                                        background: "white",
                                                        fontFamily: "Outfit",
                                                        height: '56px'
                                                    },
                                                }}
                                                style={{ color: "black", fontSize: "18px" }}
                                                isLoading={isLoading}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomDropDown
                                                name='planLevel'
                                                Title={"Plan Level"}
                                                value={AddPlanValidation.values.planLevel}
                                                onChange={AddPlanValidation.handleChange}
                                                placeholder={'Select Plan Level'}
                                                menuList={planLevelList}
                                                NoData='No Plan Level Available'
                                                isLoading={isLoading}
                                                getLabel={(item) => item}
                                                errors={AddPlanValidation.touched.planLevel && AddPlanValidation.errors.planLevel}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomTextField
                                                label="Plan Duration"
                                                type="number"
                                                min="0"
                                                max={1}
                                                inputMode="numeric"
                                                pattern="[0-9]*"
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                placeholder="Plan Duration"
                                                name="planDuration"
                                                value={AddPlanValidation.values.planDuration}
                                                onBlur={AddPlanValidation.handleBlur}
                                                errors={AddPlanValidation.touched.planDuration && AddPlanValidation.errors.planDuration}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    if (value.trimStart() !== value) {
                                                        e.target.value = value.trimStart();
                                                    }
                                                    AddPlanValidation.handleChange(e);
                                                }}
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        borderRadius: "10px",
                                                        border: AddPlanValidation.errors.planDuration ? "1px solid red" : "1px solid #BB9EFF",
                                                        color: "black",
                                                        fontSize: "18px",
                                                        background: "white",
                                                        fontFamily: "Outfit",
                                                        height: '56px'
                                                    },
                                                }}
                                                style={{ color: "black", fontSize: "18px" }}
                                                isLoading={isLoading}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                label="Plan Description"
                                                type="text"
                                                placeholder="Plan Description"
                                                name="planDescription"
                                                value={AddPlanValidation.values.planDescription}
                                                onBlur={AddPlanValidation.handleBlur}
                                                onKeyUp={AddPlanValidation.handleBlur}
                                                multiline={true}
                                                rows={3}
                                                errors={AddPlanValidation.touched.planDescription && AddPlanValidation.errors.planDescription}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    if (value.trimStart() !== value) {
                                                        e.target.value = value.trimStart();
                                                    }
                                                    AddPlanValidation.handleChange(e);
                                                }}
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        borderRadius: "10px",
                                                        border: AddPlanValidation.errors.planDescription ? "1px solid red" : "1px solid #BB9EFF",
                                                        color: "black",
                                                        fontSize: "18px",
                                                        background: "white",
                                                        fontFamily: "Outfit",

                                                    },
                                                }}
                                                style={{ color: "black", fontSize: "18px" }}
                                                isLoading={isLoading}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <CustomSubmitButton
                                                    btnTitle={isEditRunnerPlanID ? "Update" : 'Submit'}
                                                    type="submit"
                                                    sx={{
                                                        width: '130px'
                                                    }}
                                                    loading={isLoading}
                                                    onSubmit={() => AddPlanValidation.handleSubmit}
                                                />

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Grid>
                        {(location?.state?.runnerPlanId || window.sessionStorage.getItem('PlanID')) && <Grid item xs={12} md={7}>
                            <Box sx={{
                                background: '#EFE9FF80',
                                padding: { xs: "15px", lg: "20px" },
                                borderRadius: '8px'
                            }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            width: '100%'
                                        }}>
                                            <CustomDropDown
                                                name={'selectPlanDay'}
                                                value={isSelectedDay}
                                                onChange={(e) => handleSelectDaysOnChange(e)}
                                                placeholder={'Select Total Day'}
                                                menuList={totalDayPlanList}
                                                NoData='No Total Day Available'
                                                isLoading={isLoading}
                                                getLabel={(item) => item.name}
                                                sx={{
                                                    width: "200px"
                                                }}
                                                disabled={isDisabledSelectDays}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PlanWorkOutList
                                            isSelectedDays={isSelectedDays}
                                            isEditRunnerPlanID={isEditRunnerPlanID}
                                            isSelectedDay={isSelectedDay}
                                            isSelectedRunnerPlanDetails={isSelectedRunnerPlanDetails}
                                            handleWorkOutOnSubmit={handleWorkOutOnSubmit}
                                            openAddWorkOutModel={openAddWorkOutModel}
                                            setOpenAddWorkOutModel={setOpenAddWorkOutModel}
                                            onDeleteUpdate={handleDeletePlanDetailsUpdate}
                                            handleDeletePlandetailsDayAllPosition={handleDeletePlandetailsDayAllPosition}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>}
                    </Grid>
                </Box>
            </Box>
        </Fragment>

    )
}

export default AddRunnerPlan;