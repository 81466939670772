import React, { Fragment, useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import PROFILE_PHOTO_USER from "../../../Assets/Images/Common/avtar.png";
import AxiosInstance from "../../../Config/AxiosInstance";
import APILIST from "../../../Config/ApiList";

const ContactUs = () => {
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const columns = [
    { field: "rowid", headerName: "Sr.No.", width: 100 },
    {
      field: "full_name",
      headerName: "Name",
      flex: 1,
      minWidth: 300,
      renderCell: (params) =>
        params?.row?.full_name ? params?.row?.full_name : "--",
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Tooltip title={params?.row?.subject || "--"}>
          <span>{params?.row?.subject || "--"}</span>
        </Tooltip>
      ),
    },
    {
      field: "message",
      headerName: "Description",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Tooltip title={params?.row?.message || "--"}>
          <span>{params?.row?.message || "--"}</span>
        </Tooltip>
      ),
    },


  ];
  useEffect(() => {
    getContactUsList();
  }, [page]);
  const getContactUsList = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(APILIST.GET_CONTACT_US, {
        params: {
          count: page * pageSize,
          offset: pageSize,
        }
      });
      if (response && response?.data?.s) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setUserList(Rowdata);
        setCount(response?.data?.c || 0);
      } else {
        setUserList([]);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <Fragment>
      <Box
        sx={{
          width: "100%",
          padding: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "30px" },
              fontWeight: "500",
              paddingBottom: "10px",
            }}
          >
            Contact Us
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "24px 0px 24px 0px",
          }}
        >
          <Box sx={{ padding: "24px 0px 24px 0px" }}>
            <TableContainer
              rows={userList}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              page={page}
              totalCount={count}
              handlePageChange={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default ContactUs
