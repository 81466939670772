import React, { useEffect, useState } from "react";
import UserProfile from "./UserProfile";
import { Box, Grid, Typography } from "@mui/material";
import { DATABASE, STORAGE } from "../../../Config/firebase";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
const UsersList = ({ setSelectedChat }) => {
  const [chatList, setChatList] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  useEffect(() => {
    const q = query(collection(DATABASE, "support"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chats = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChatList(chats);
    });
    return () => unsubscribe();
  }, []);
  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
    setSelectedChatId(chat?.id);
  };
  return (
    <Box sx={{
      background: "#EFE9FF",
      width: "350px",
      padding: "20px 20px 20px 20px",
    }}>
      <Typography
        sx={{ color: "#000000", fontWeight: "500", fontSize: "30px",padding: "0px 0px 20px 0px", }}
      >
        Chats
      </Typography>
      <Box
      sx={{
        overflowY: "auto",
        height:'calc(100vh - 100px)',
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
      }}
    >
      <Grid container spacing={3}>
        {chatList &&
          chatList?.length > 0 &&
          chatList?.map((elem, index) => {
            return (
              <UserProfile
                key={index}
                chatList={elem}
                handleSelectChat={(userProfileData) => {
                  handleSelectChat(elem);
                  setSelectedChat({ ...elem, userProfileData });
                }}
                isSelected={selectedChatId === elem?.id}
              />
            );
          })}
      </Grid>
    </Box>
    </Box>
   
  );
};

export default UsersList;
